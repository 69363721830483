<template>
    <div class="page-table haberTanim mainDiv mb-30" id="affix-container">
      <div class="animated fadeInUp card-base baslikBg">
        {{ $t("src.views.apps.sayfa.tanimlama.title") }}
      </div>
      <el-row
        :gutter="10"
        v-loading="loading"
        :element-loading-text="$t('src.views.apps.sayfa.tanimlama.addLoading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
      >
        <el-form
          status-icon
          :model="sayfaForm"
          :rules="rulesSayfalar"
          ref="sayfaForm"
          class="demo-ruleForm"
        >
          <el-col :lg="18" :md="18" :sm="24" :xs="24">
            <div
              class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft"
            >
      
              <el-row>
                  
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                  <el-form-item :label="$t('src.views.apps.sayfa.tanimlama.baslik')" prop="baslik">:
                    <el-input v-model="sayfaForm.baslik" v-on:blur="linkTespit()" :placeholder="$t('src.views.apps.sayfa.tanimlama.baslikPlace')"></el-input>
                  </el-form-item>
                </el-col>
  
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                  <el-form-item :label="$t('src.views.apps.sayfa.tanimlama.altBaslik')" prop="altBaslik" >:
                    <el-input v-model="sayfaForm.altBaslik" :placeholder="$t('src.views.apps.sayfa.tanimlama.altBaslikPlace')"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-row v-if="sayfaForm.gorunum == '1'">
                <el-col :lg="20" :md="20" :sm="20" :xs="20" class="col-p">
                  <el-form-item
                    :label="$t('src.views.apps.sayfa.guncelleme.menuBaslik')"
                    prop="menuBaslik"
                    >:
                    <el-input
                      size="small"
                      v-model="sayfaForm.menuBaslik"
                      :placeholder="
                        $t('src.views.apps.sayfa.guncelleme.menuBaslikPlace')
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
  
                <el-col :lg="4" :md="4" :sm="4" :xs="4" class="col-p">
                  <el-form-item
                    :label="$t('src.views.apps.sayfa.guncelleme.menuSiraNo')"
                    prop="menuSiraNo"
                    >:
                    <el-input
                      size="small"
                      v-mask="'##'"
                      v-model="sayfaForm.menuSiraNo"
                      :placeholder="
                        $t('src.views.apps.sayfa.guncelleme.menuSiraNoPlace')
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
  
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                  <el-form-item
                    :label="$t('src.views.apps.sayfa.guncelleme.menuGorunum')"
                    prop="ustMenuID"
                    >:
                    <el-select style="width: 100%" v-model="sayfaForm.ustMenuID">
                      <el-option
                        value="0"
                        :label="$t('src.views.apps.sayfa.guncelleme.anaMenu')"
                      ></el-option>
                      <el-option
                        value="1"
                        :label="$t('src.views.apps.sayfa.guncelleme.altMenu')"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
  
                <el-col
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                  class="col-p"
                  v-if="sayfaForm.ustMenuID != '0'"
                >
                  <el-form-item
                    :label="$t('src.views.apps.sayfa.guncelleme.ustMenu')"
                    prop="ustMenuSayfaID"
                    >:
                    <el-select
                      style="width: 100%"
                      v-model="sayfaForm.ustMenuSayfaID"
                    >
                      <el-option
                        v-for="(item, index) in menuSayfaList"
                        :key="index"
                        :value="item.menuID"
                        :label="item.baslik"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-row>
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                  <el-form-item
                    :label="$t('src.views.apps.sayfa.tanimlama.icerik')"
                    prop="icerik"
                    >:
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      v-model="sayfaForm.icerik"
                      @input="seoIcerikTespit()"
                      @blur="seoIcerikTespit()"
                    ></ckeditor>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                                  <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                      <el-form-item>
                                          <el-checkbox v-model="videoVis">{{$t("src.views.apps.bultenler.guncelleme.videoOlsunMu")}}</el-checkbox>
                                      </el-form-item>
                                  </el-col>
                              </el-row>
            </div>
            <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                                  <el-row :gutter="10">
                                      <el-col v-if="videoVis" style="float: right" :lg="12" :md="12" :sm="24" :xs="24">
                                          <el-collapse-item class=" animated fadeInDown card-shadow--medium" :title='$t("src.views.apps.bultenler.guncelleme.video")' name="2">
                                              <el-row>
                                                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                      <el-form-item :label='$t("src.views.apps.bultenler.guncelleme.video")' prop="video">:
                                                          <el-input size="small"
                                                          type="textarea"
                                                          :rows="5"
                                                          v-model="sayfaForm.video"
                                                          :placeholder='$t("src.views.apps.bultenler.guncelleme.videoURLGir")'></el-input>
                                                      </el-form-item>
                                                  </el-col>
                                              </el-row>
                                          </el-collapse-item>
                                      </el-col>
                                  </el-row>
                          </el-collapse>
            
  
            <el-collapse
              class="animated fadeInUp"
              style="margin-top: 10px"
              v-model="activeCollapse">
              <draggable data-source="juju" class="list-group" group="a">
                <el-collapse-item
                  class="card-shadow--medium"
                  :title="$t('src.views.apps.sayfa.guncelleme.resimAyar')"
                  name="3">
                  <Resim functionName="sayfaTanimlaImage" :selectedsFromServer="selectedImagesFromServer"></Resim>
                </el-collapse-item>
  
                <!-- <el-collapse-item
                                  class="card-shadow--medium"
                                  title="Belge Yükleme"
                                  name="4" >
                                  <Belge functionName="sayfaTanimlaBelge" :selectedsFromServer="selectedFilesFromServer"></Belge>
                              </el-collapse-item> -->
              </draggable>
            </el-collapse>
  
            <el-collapse
              class="animated fadeInUp"
              style="margin-top: 10px"
              v-model="activeCollapse"
            >
              <el-collapse-item
                class="card-shadow--medium"
                :title="$t('src.views.apps.sayfa.guncelleme.seoAyar')"
                name="5"
              >
                <el-row>
                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                    <el-form-item
                      :label="$t('src.views.apps.sayfa.tanimlama.seoBaslik')"
                      prop="seoBaslik"
                      >:
                      <el-input
                        id="seoBaslik"
                        v-model="sayfaForm.seoBaslik"
                        @input="seoBaslikChange('seoBaslik')"
                        :placeholder="
                          $t('src.views.apps.sayfa.tanimlama.seoBaslikPlace')
                        "
                      >
                        <template slot="append">{{ seoBaslikLimit }}</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
  
                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                    <el-form-item
                      :label="$t('src.views.apps.sayfa.tanimlama.seoIcerik')"
                      prop="seoIcerik"
                      >:
                      <el-input
                        type="textarea"
                        v-on:focus="seoIcerikChange = false"
                        v-model="sayfaForm.seoIcerik"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-col>
  
          <el-col :lg="6" :md="6" :sm="24" :xs="24">
            <el-collapse
              v-model="activeCollapse"
              class="yanForm animated fadeInUp"
            >
              <el-collapse-item
                class="card-shadow--medium"
                name="9"
                :title="$t('src.views.apps.sayfa.tanimlama.durum')"
              >
                <el-row>
                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                    <el-form-item prop="durum">
                      <el-select
                        v-on:change="$store.commit('changeTabsClose', false)"
                        v-model="sayfaForm.durum"
                        style="width: 100%"
                      >
                        <el-option
                          value="0"
                          :label="$t('src.views.apps.sayfa.tanimlama.taslak')"
                        ></el-option>
                        <el-option
                          value="1"
                          :label="$t('src.views.apps.sayfa.tanimlama.sayfaEkle')"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-button
                    size="mini"
                    v-on:click="sayfaTanimlama('sayfaForm', sayfaForm.durum)"
                    class="onayBtn"
                    type="primary"
                  >
                    <label v-if="sayfaForm.durum === '1'">{{
                      $t("src.views.apps.sayfa.tanimlama.sayfaEkle")
                    }}</label>
                    <label v-else>{{
                      $t("src.views.apps.sayfa.tanimlama.taslakOlarakKaydet")
                    }}</label>
                  </el-button>
                  &nbsp;
                  <el-popover placement="bottom" width="300" v-model="visibleSil">
                    <p>{{ $t("src.views.apps.messages.clearFormAlert") }}</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="primary"
                        @click="resetForm('sayfaForm')"
                      >
                        {{ $t("src.views.apps.genel.yes") }}
                      </el-button>
                      <el-button size="mini" @click="visibleSil = false">
                        {{ $t("src.views.apps.genel.no") }}
                      </el-button>
                    </div>
                    <el-button size="mini" slot="reference">
                      {{ $t("src.views.apps.genel.temizle") }}
                    </el-button>
                  </el-popover>
                </el-row>
              </el-collapse-item>
  
              <draggable class="list-group" group="a">
                <el-collapse-item
                  class="card-shadow--medium"
                  :title="$t('src.views.apps.sayfa.tanimlama.sayfatipi')"
                  name="10"
                >
                  <el-row>
                      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                          <el-form-item prop="sayfaTuru">
                                              <!-- v-on:change="getTreeList" -->
                                              <el-select
                                                      v-model="sayfaForm.sayfaTuru"
                                                      style="width: 100%"
                                                      v-on:change="sayfaTuruChange">
                                                  <el-option value="0" :label='$t("src.views.apps.sayfa.guncelleme.sayfa")'></el-option>
                                                  <el-option value="1" :label='$t("src.views.apps.sayfa.guncelleme.altSayfa")'></el-option>
                                              </el-select>
                                          </el-form-item>
                                      </el-col>
                                      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                          <el-form-item
                                                  v-if="visibleUstSayfa"
                                                  :label='$t("src.views.apps.sayfa.tanimlama.sayfaTipi")'
                                                  prop="sayfaTuru">:
                                                  <el-select
                                                      size="small"
                                                      v-model="sayfaForm.secilenSayfaTuru"
                                                      style="width: 100%">
                                                      <el-option v-for="(item, index) in treeList" :value="item.sayfaID" :key="index" :label="item.name"></el-option>
                                              </el-select>
                                          </el-form-item>
                                          <div style="font-style: italic">
                                              {{$t("src.views.apps.sayfa.tanimlama.sayfaTuruAciklama")}}
                                          </div>
                                      </el-col>
                  </el-row>
                  <el-row v-if="sayfaForm.sayfaTuru != '1'">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                      <el-form-item class="margin-top-10">
                        <el-button
                          size="mini"
                          type="success"
                          v-on:click="iconListDialog = true"
                          icon="el-icon-search"
                        >
                          {{ $t("src.views.apps.sayfa.guncelleme.iconSec") }}
                        </el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-collapse-item>
  
                <el-collapse-item
                  class="card-shadow--medium"
                  :title="$t('src.views.apps.sayfa.tanimlama.gorunum')"
                  name="11"
                >
                  <el-row>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                      <el-form-item prop="gorunum">
                        <el-switch
                          v-on:change="$store.commit('changeTabsClose', false)"
                          v-model="sayfaForm.gorunum"
                          active-value="1"
                          inactive-value="0"
                          active-text="Göster"
                          inactive-text="Gösterme"
                        >
                        </el-switch>
                      </el-form-item>
                      <div style="font-style: italic">
                        {{ $t("src.views.apps.sayfa.tanimlama.menuAciklama") }}
                      </div>
                    </el-col>
                  </el-row>
                </el-collapse-item>
  
                <!-- <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.tanimlama.resimKonum")' name="12">
                                  <el-row>
                                      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                          <el-form-item prop="resimKonum">
                                              <el-button type="primary" size="mini" @click="drawerResimKonum = true">
                                                  {{this.btnResim}}
                                              </el-button>
                                              &nbsp;
                                              <img v-if="sayfaForm.resimKonum === '0'" style="float: right"
                                                   src="../../../assets/images/1.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.resimKonum === '1'" style="float: right"
                                                   src="../../../assets/images/2.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.resimKonum === '2'" style="float: right"
                                                   src="../../../assets/images/5.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.resimKonum === '3'" style="float: right"
                                                   src="../../../assets/images/4.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.resimKonum === '4'" style="float: right"
                                                   src="../../../assets/images/3.png" width="50%" height="50%">
                                          </el-form-item>
                                          <div style="font-style: italic">
                                              {{$t("src.views.apps.sayfa.tanimlama.resimKonumAciklama")}}
                                          </div>
                                      </el-col>
                                  </el-row>
                              </el-collapse-item>
                              <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.tanimlama.menuKonum")' name="13">
                                  <el-row>
                                      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                          <el-form-item prop="menuKonum">
                                              <el-button type="primary" size="mini" @click="drawerMenuKonum = true">
                                                  {{this.btnMenu}}
                                              </el-button>
                                              &nbsp;
                                              <img v-if="sayfaForm.menuKonum === '0'" style="float: right"
                                                   src="../../../assets/images/1.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.menuKonum === '1'" style="float: right"
                                                   src="../../../assets/images/6.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.menuKonum === '2'" style="float: right"
                                                   src="../../../assets/images/9.png" width="50%" height="50%">
                                          </el-form-item>
                                          <div style="font-style: italic">
                                              {{$t("src.views.apps.sayfa.tanimlama.menuKonumAciklama")}}
                                          </div>
                                      </el-col>
                                  </el-row>
                              </el-collapse-item>
                              <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.tanimlama.galeriKonum")' name="14">
                                  <el-row>
                                      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                          <el-form-item prop="galeriKonum">
                                              <el-button type="primary" size="mini" @click="drawerGaleriKonum = true">
                                                  {{this.btnGaleri}}
                                              </el-button>
                                              &nbsp;
                                              <img v-if="sayfaForm.galeriKonum === '0'" style="float: right"
                                                   src="../../../assets/images/1.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.galeriKonum === '1'" style="float: right"
                                                   src="../../../assets/images/7.png" width="50%" height="50%">
                                              <img v-if="sayfaForm.galeriKonum === '2'" style="float: right"
                                                   src="../../../assets/images/8.png" width="50%" height="50%">
                                          </el-form-item>
                                          <div style="font-style: italic">
                                              {{$t("src.views.apps.sayfa.tanimlama.galeriKonumAciklama")}}
                                          </div>
                                      </el-col>
                                  </el-row>
                              </el-collapse-item> -->
              </draggable>
            </el-collapse>
          </el-col>
        </el-form>
  
        <el-dialog
          :title="$t('src.views.apps.sayfa.tanimlama.iconList')"
          :visible.sync="iconListDialog"
          width="60%"
          center
        >
          <div>
            <el-row class="iconRow">
              <el-input
                class="margin-bottom-10"
                placeholder="Listede Olmayan Bir Icon Girebilirsiniz."
                size="medium"
                v-model="sayfaForm.icon"
              ></el-input>
              <el-col
                v-for="(item, index) in iconListe"
                :key="item"
                :label="item"
                :value="item"
                :lg="4"
                :md="4"
                :sm="8"
                :xs="8"
                style="
                  border: 1px solid #9a9a9a;
                  padding: 5px;
                  height: 100px;
                  text-align: center;
                  display: table;
                "
              >
                <div
                  v-on:click="iconSelect(item)"
                  class="iconCerceve"
                  id="#iconCerceve"
                >
                  <el-button type="text">
                    <i
                      style="font-size: 24px; text-align: center"
                      :class="item"
                    ></i>
                  </el-button>
                  <br />
                  <span class="icon-name" :data-class="item">{{ item }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-dialog>
  
        <!-- <el-dialog
                      title="Resim Konumu"
                      :visible.sync="drawerResimKonum"
                      width="60%">
                  <el-row>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.resimKonum" label="0" @change="changeKonum('resim','0')">
                              <img src="../../../assets/images/1.png" alt="Yok" width="90%" height="90%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.resimKonum" label="1" @change="changeKonum('resim','1')">
                              <img src="../../../assets/images/2.png" alt="Sağ" width="90%" height="90%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.resimKonum" label="2" @change="changeKonum('resim','2')">
                              <img src="../../../assets/images/5.png" alt="Alt" width="90%" height="90%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.resimKonum" label="3" @change="changeKonum('resim','3')">
                              <img src="../../../assets/images/4.png" alt="Sol" width="90%" height="90%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.resimKonum" label="4" @change="changeKonum('resim','4')">
                              <img src="../../../assets/images/3.png" alt="Üst" width="90%" height="90%">
                          </el-radio>
                      </el-col>
                  </el-row>
              </el-dialog>
              <el-dialog
                      title="Menü Konumu"
                      :visible.sync="drawerMenuKonum"
                      width="50%">
                  <el-row>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.menuKonum" label="0" @change="changeKonum('menu','0')">
                              <img src="../../../assets/images/1.png" alt="Yok" width="80%" height="80%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.menuKonum" label="1" @change="changeKonum('menu','1')">
                              <img src="../../../assets/images/6.png" alt="Sağ" width="80%" height="80%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.menuKonum" label="2" @change="changeKonum('menu','2')">
                              <img src="../../../assets/images/9.png" alt="Alt" width="80%" height="80%">
                          </el-radio>
                      </el-col>
                  </el-row>
              </el-dialog>
              <el-dialog
                      title="Galeri Konumu"
                      :visible.sync="drawerGaleriKonum"
                      width="50%">
                  <el-row>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.galeriKonum" label="0" @change="changeKonum('galeri','0')">
                              <img src="../../../assets/images/1.png" alt="Yok" width="80%" height="80%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.galeriKonum" label="1" @change="changeKonum('galeri','1')">
                              <img src="../../../assets/images/7.png" alt="Dikey,Büyük" width="80%" height="80%">
                          </el-radio>
                      </el-col>
                      <el-col :span="8">
                          <el-radio v-model="sayfaForm.galeriKonum" label="2" @change="changeKonum('galeri','2')">
                              <img src="../../../assets/images/8.png" alt="Yatay,Küçük" width="80%">
                          </el-radio>
                      </el-col>
                  </el-row>
              </el-dialog> -->
      </el-row>
    </div>
  </template>
  
  <script>
  import Resim from "../../../components/Resim.vue";
  import Belge from "../../../components/Belge.vue";
  
  import menuService from "../../../WSProvider/MenuService";
  import sayfaService from "../../../WSProvider/SayfaService";
  
  import ClassicEditor from "../../../../node_modules/ckeditor5-build-classic";
  import EventBus from "@/components/event-bus";
  import JQuery from "jquery";
  
  let $ = JQuery;
  import draggable from "vuedraggable";
  import notification from "../../../notification";
  import functions from "../../../functions";
  import iconJson from "../../../icon";
  import "@fortawesome/fontawesome-free/css/all.css";
  import "@fortawesome/fontawesome-free/js/all.js";
  
  export default {
    name: "SayfaTanimlama",
    components: {
      draggable,
      Belge,
      Resim,
    },
    computed: {
      imageList() {
        return this.sayfaForm.resim;
      },
    },
    watch: {
      // resim konum galeri konum vs suanda ön tarafta uygun bir yapı olmadıgı için sunucuya varsayılan gonderiyoruz. Oyuzden secimleri kaldırdık. Ona uygun bir yapı hazırlandıgında yorum satırı kaldırılır.
      // imageList: function (val) {
      //     if (val.length === 1) {
      //         this.sayfaForm.resimKonum = "1";
      //         this.sayfaForm.galeriKonum = "0";
      //     } else if (val.length > 1) {
      //         this.sayfaForm.galeriKonum = "1";
      //     } else {
      //         this.sayfaForm.resimKonum = "0";
      //         this.sayfaForm.galeriKonum = "0";
      //     }
      // }
    },
    data() {
      return {
          sayfa: "sayfa",
          galeri: "galeri",
          showRow: true,
        denemeStr: "sdmkjfdnjdfnjdkfmdfkldf",
        totalSayfa: 0,
  
        loading: false,
        loadingSayfaList: false,
  
        selectedImagesFromServer: [],
        selectedFilesFromServer: [],
        imagePath: sayfaService.imagePath,
        belgePath: sayfaService.belgePath,
  
        iconListe: iconJson["v5.0.9"],
        iconListDialog: false,
        seoBaslikLimit: 0,
        drawerResimKonum: false,
        drawerMenuKonum: false,
        drawerGaleriKonum: false,
        activeCollapse: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
        ],
        videoVis: false,
        editor: ClassicEditor,
        editorConfig: {
          language: "tr",
        },
  
        //Tree
        visibleUstSayfa: false,
        treeList: [],
        // Form
        btnResim: "Seç",
        btnMenu: "Seç",
        btnGaleri: "Seç",
        seoIcerikChange: true,
        visibleSil: false,
  
        sayfaListe: [],
        menuSayfaList: [],
        sayfaForm: {
          sayfaDurum: "sayfa",
          baslik: "",
          altBaslik: "",
          icerik: "",
          video: "",
          resim: [],
          belge: [],
          seoBaslik: "",
          seoIcerik: "",
          durum: "1",
          link: "",
          sayfaTuru: "0",
          secilenSayfaTuru: "",
          ustsayfaId: "",
          belgeKategori: "",
  
          resimKonum: "0",
          menuKonum: "0",
          galeriKonum: "0",
  
          gorunum: "1", // menude gorunup gorunmeyecegi
          ustMenuID: "0",
          ustMenuSayfaID: "",
          ustSayfaID: "0",
          menuBaslik: "",
          menuKategoriID: "",
          menuSiraNo: "",
          icon: "",
        },
        ilkEkleme: false,
        rulesSayfalar: {
          baslik: [
            {
              required: true,
              message: "Lütfen Başlık Giriniz",
              trigger: "blur",
            },
          ],
          altBaslik: [
            {
              required: true,
              message: "Lütfen Alt Başlık Giriniz",
              trigger: "blur",
            },
          ],
          icerik: [
            {
              required: true,
              message: "Lütfen İçerik Giriniz",
              trigger: "blur",
            },
          ],
        },
      };
    },
    mounted() {
      var self = this;
  
      EventBus.$on("sayfaTanimlaImage", function (bool, imageArray) {
        if (bool) {
          self.sayfaForm.resim = imageArray;
        }
      });
  
      EventBus.$on("sayfaTanimlaBelge", function (bool, belgeArray) {
        if (bool) {
          self.sayfaForm.belge = belgeArray;
        }
      });
      this.getSayfaCount();
      this.getMenuSayfalar();
    },
    methods: {
      getMenuSayfalar() {
        try {
          menuService
            .menuListe("1")
            .then((response) => {
              if (response.status === 200) {
                this.menuSayfaList = response.data;
                localStorage.setItem("userDataDemirag", response.token);
              }
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.menuSayfaList = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              }
            });
        } catch (e) {}
      },
  
      linkTespit() {
        this.sayfaForm.seoBaslik = this.sayfaForm.baslik.substring(0, 60);
      },
  
      sayfaTuruChange(val) {
        if (val == "1") this.getTreeList("1");
        else this.visibleUstSayfa = false;
      },
  
      getSayfaCount() {
        try {
          sayfaService
            .sayfaDurumSayisi(1)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataDemirag", response.token);
                this.totalSayfa = response.data;
                this.getSayfaList(1);
              }
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.totalSayfa = 0;
                } else notification.Status("errorMsg", this, error.errMsg);
              }
            });
        } catch (e) {}
      },
  
      getSayfaList(durum) {
        try {
          this.loadingSayfaList = true;
          sayfaService
            .sayfaList(0, this.totalSayfa, durum)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataDemirag", response.token);
                // this.sayfaListe = response.data.data;
                response.data.forEach((element) => {
                  if (element.ustSayfaID === "0") this.sayfaListe.push(element);
                });
              }
              this.loadingSayfaList = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.sayfaListe = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              }
              this.loadingSayfaList = false;
            });
        } catch (e) {
          this.loadingSayfaList = false;
        }
      },
  
      iconSelect(value) {
        this.sayfaForm.icon = value;
        $("body").find(".aktifIkon").removeClass("aktifIkon");
        $(".iconRow")
          .find('span[data-class="' + value + '"]')
          .closest(".iconCerceve")
          .parent("div")
          .addClass("aktifIkon");
        notification.Status("success", this, value + " iconu seçildi.");
      },
  
      seoBaslikChange(id) {
        this.seoBaslikLimit = this.sayfaForm.seoBaslik.length;
        if (this.seoBaslikLimit > 70) {
          $("#" + id)
            .closest("div")
            .find("div")
            .addClass("limitAsim");
        } else {
          $("#" + id)
            .closest("div")
            .find("div")
            .removeClass("limitAsim");
        }
      },
      seoIcerikTespit() {
        if (this.seoIcerikChange && this.sayfaForm.icerik) {
          const myStr = functions.strip_html_tags(this.sayfaForm.icerik);
          this.sayfaForm.seoIcerik = myStr.substring(0, 140);
        } else this.sayfaForm.seoIcerik = "";
      },
  
      getTreeList(value) {
        const self = this;
        if (value === "1") {
          try {
            sayfaService
              .sayfaList(0, 500, 1)
              .then((response) => {
                if (response.status == 200) {
                  localStorage.setItem("userDataDemirag", response.token);
                  if (response.data.length > 0) {
                    response.data.forEach(function (item, index) {
                      self.treeList.push({
                        id: index,
                        name: item.baslik,
                        sayfaID: item.sayfaID,
                      });
                    });
                  }
                  self.visibleUstSayfa = true;
                }
              })
              .catch((err) => {
                if (err.responseJSON) {
                  let error = err.responseJSON;
                  if (error.status == 401) {
                    notification.Status(150, this);
                  } else if (error.status == 404) {
                    this.treeList = [];
                    self.visibleUstSayfa = true;
                  } else notification.Status("errorMsg", this, error.errMsg);
                } else {
                  //notification.Status(503, this)
                }
              });
          } catch (e) {}
        } else {
          self.visibleUstSayfa = false;
        }
      },
  
      sayfaTanimlama(formName, durum) {
        if (this.validateForm(formName)) {
          this.openConfirmDialog(
            "Sayfayı tanımlamak istediğinize emin misiniz?"
          ).then(() => {
            try {
              this.loading = true;
              var sunucudanEklenenResimler =
                this.selectedImagesFromServer.join(",");
              sayfaService.sayfaEkle(
                  this.sayfaForm.secilenSayfaTuru,
                  this.sayfaForm.gorunum,
                  this.sayfaForm.ustMenuID == "0"
                    ? "0"
                    : this.sayfaForm.ustMenuSayfaID,
                  this.sayfaForm.gorunum == "1" ? "1" : "0",
                  this.sayfaForm.gorunum == "1" ? this.sayfaForm.menuBaslik : "",
                  this.sayfaForm.gorunum == "1" ? this.sayfaForm.menuSiraNo : "",
                  this.sayfaForm.baslik,
                  this.sayfaForm.altBaslik,
                  this.sayfaForm.icerik,
                  sunucudanEklenenResimler,
                  this.sayfaForm.resim.length > 0 ? this.sayfaForm.resim : "",
                  this.sayfaForm.icon,
                  this.sayfaForm.resimKonum,
                  this.sayfaForm.menuKonum,
                  this.sayfaForm.galeriKonum,
                  durum,
                  this.sayfaForm.seoBaslik,
                  this.sayfaForm.seoIcerik,
                  this.sayfaForm.sayfaDurum,
                  this.sayfaForm.video
                )
                .then((response) => {
                  if (response.status == 200) {
                    localStorage.setItem("userDataDemirag", response.token);
                    this.resetForm(formName);
                    EventBus.$emit("sayfaList", true);
                    this.sayfaKapat("sayfatanimla");
                    notification.Status("success", this, response.msg);
                  }
                  this.loading = false;
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else notification.Status("errorMsg", this, error.errMsg);
                  } else {
                    //notification.Status(503, this)
                  }
                  this.loading = false;
                });
            } catch (e) {
              this.loading = false;
            }
          });
        }
      },
  
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.visibleSil = false;
        this.sayfaForm.belge = [];
        this.sayfaForm.sayfaTuru = "0";
        this.sayfaForm.ustsayfaId = "";
        this.visibleUstSayfa = false;
        this.sayfaForm.link = null;
      },
  
      changeKonum(item, konum) {
        if (item === "resim") {
          this.drawerResimKonum = false;
          this.btnResim = "Değiştir";
          switch (konum) {
            case "0":
              notification.Status(
                "success",
                this,
                'Resim konumu "Yok" olarak seçildi.'
              );
              break;
            case "1":
              notification.Status(
                "success",
                this,
                'Resim konumu "Sağ" olarak seçildi.'
              );
              break;
            case "2":
              notification.Status(
                "success",
                this,
                'Resim konumu "Alt" olarak seçildi.'
              );
              break;
            case "3":
              notification.Status(
                "success",
                this,
                'Resim konumu "Sol" olarak seçildi.'
              );
              break;
            case "4":
              notification.Status(
                "success",
                this,
                'Resim konumu "Üst" olarak seçildi.'
              );
              break;
          }
        } else if (item === "menu") {
          this.drawerMenuKonum = false;
          this.btnMenu = "Değiştir";
          switch (konum) {
            case "0":
              notification.Status(
                "success",
                this,
                'Menü konumu "Yok" olarak seçildi.'
              );
              break;
            case "1":
              notification.Status(
                "success",
                this,
                'Menü konumu "Sağ" olarak seçildi.'
              );
              break;
            case "2":
              notification.Status(
                "success",
                this,
                'Menü konumu "Alt" olarak seçildi.'
              );
              break;
          }
        } else if (item === "galeri") {
          this.drawerGaleriKonum = false;
          this.btnGaleri = "Değiştir";
          switch (konum) {
            case "0":
              notification.Status(
                "success",
                this,
                'Galeri konumu "Yok" olarak seçildi.'
              );
              break;
            case "1":
              notification.Status(
                "success",
                this,
                'Galeri konumu "Dikey,Büyük" olarak seçildi.'
              );
              break;
            case "2":
              notification.Status(
                "success",
                this,
                'Galeri konumu "Yatay,Küçük" olarak seçildi.'
              );
              break;
          }
        }
      },
    },
  };
  </script>
  